@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import 'variables.scss'; 

body {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #000;
  font-size: 15px;
  line-height: 24px;
  height: 100%;
  a{
    color: inherit;
  }
}

.open {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.container{
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 20px;
}

.title{
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  margin: 0px;
  padding: 50px 0;
  @media screen and (min-width: $tablet-max) {
    font-size: 40px;
    line-height: 40px;
  }
}


