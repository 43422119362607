@import '../../variables.scss'; 
 
.footer{
  width: 100%;
  background-color: #323131;
  color:white;
  padding-bottom: 20px;
  margin-top: 50px;
  border-top: 3px solid #b7903c;

  &-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  &-item {
    width: 100%;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;

    @media screen and (min-width: $tablet-max) {
      width: calc(33% - 80px);
      text-align: left;
    }

    &:last-child{
      display: inline;
    }
  
    h6{
      text-transform: uppercase;
      font-size: 15px;
      margin: 20px 0px;
      color: #d1b683;
      width: 100%;
    }

    p {
      margin: 0;
    }

    &.place {
      ul {
        padding: 0;
        margin: 0;
        li {
          list-style: none;
          display: inline;
        }
      }
    }

    .social {
      width: 15px;
      height: 15px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      &.facebook {
        background-image: url(../../img/facebook-footer.png);
      }
      &.instagram {
        background-image: url(../../img/instagram-footer.png);
        margin-right: 10px;
      }
    }
  }
}