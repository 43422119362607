@import '../../variables.scss'; 

.map {
  .items {
    display: flex;
    flex-wrap: wrap;
    background-color: #b8903da8;
    
    .hours {
      ul {
        display: flex;
        flex-wrap: wrap;
        width: fit-content;
        padding: 0 20px;

        @media screen and (min-width: $tablet-max) {
          padding: 0;
        }
      }
      li {
        list-style: none;
        display: flex;
        width: 100%;
        align-items: center;

        span {
          min-width: 100px;
          text-align: left;
        }

        p {
          font-weight: 300;
          margin: 0;
          color: black;
          font-size: 15px;
        }
      }

      p {
        color: #9c0504;
        font-size: 18px;
      }
    }

    .phone {
      a {
        width: 100%;
        display: block;
        text-decoration: none;
        cursor: pointer;
        &:first-of-type{
          margin-top: 20px;
        }
        &:last-child {
          span {
            background-image: url(../../img/old-typical-phone.png);
          }
        }
      }

      span {
        background-image: url(../../img/phone-call.png);
        width: 18px;
        height: 18px;
        display: inline-block;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 5px;
        margin-top: 5px;
      }
    }
  
    .item {
      padding: 20px;
      display: inline-block;
      flex-grow: 1;
      width: 100%;
      max-width: 100%;
      border-bottom: 1.5px solid #fff;
      text-align: center;
      &.address {
        span {
          background-image: url(../../img/pin.png);
          width: 20px;
          height: 20px;
          display: inline-block;
          background-position: center center;
          background-size: contain;
          margin-right: 5px;
          background-repeat: no-repeat;
        }

        p {
          display: inline-block;
        }
      }
      &:last-child{
        border-right: 0px solid #fff;
        border-bottom: 0px solid #fff;
      }

      @media screen and (min-width: $tablet-max) {
        width: calc(33% - 20px);
        max-width: calc(33% - 40px);
        border-right: 1.5px solid #fff;
        border-bottom: 0px;
        text-align: left;
      }
  
      h6 {
        color: #fff;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 500;
        padding-right: 10px;
        margin: 0;
      }
    }
  }
  
}