@import '../../variables.scss'; 
header{
  position: fixed;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 5px 0px;
  width: 100%;

  @media screen and (min-width: $tablet-max) {
    padding: 15px;
    width: calc(100% - 30px);
    background-color: transparent;
   }


  &.slim-header{
    background-color: white;

    @media screen and (min-width: $tablet-max-max) {
      width: 100%;
     }

    img {
      transform: scale(0.7);
    }
    .items-mobile{
      img {
        transform: scale(1);
      }
    }
  }

  nav {
    display: flex;

    img {
      height: auto;
      max-width: 70%;
      transition: transform 0.5s ease; 
      @media screen and (min-width: $tablet-max-max) {
        max-width: 100%;
       }
    }

    .items{
      list-style: none;
      align-items: center;
      padding-left: 10px;
      position: relative;
      display: none;
      li {
        margin: 0 20px 0 0;
        display: flex;
        align-items: center;
          p{
            text-decoration: none;
            line-height: 1.375em;
            letter-spacing: .2em;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 12px;
            cursor: pointer;
          }

          span{
            background-image: url(../../img/pizza-cutter.png);
            background-position: center center;
            display: block;
            background-repeat: no-repeat;
            background-size: contain;
            height: 20px;
            width: 20px;
            margin-right: 10px;
            opacity: 0;
            transition: opacity 0.9s ease;

            &.active {
              opacity: 1;
            }
          }
      }

      @media screen and (min-width: $tablet-max-max) {
       display: flex;
      }
    }

  }

  .icon-menu-mobile{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    display: flex;
    gap: 3px;
    background-color: #ffffffb0;
    padding: 8px;
    border-radius: 100%;
    z-index: 99;

    @media screen and (min-width: $tablet-max-max) {
      display: none;
     }
   
    span {
      border-radius: 20px;
      background-color: black;
      width: 100%;
      height: 3px;
    }

    &[data-active=true] {
      background-color: black;
      span {
        &:first-child {
          transform: rotate(-45deg);
        }
        &:nth-child(2) {
          display: none;
        }
        transform: rotate(45deg);
        position: absolute;
        width: 20px;
        background-color: white;
      }
    }
   
  }

  .items-mobile{
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 9;
    background-color: white;
    margin: 0;
    top: 0;
    left: 0;
    padding: 0;
    display: none;

    li {
      list-style: none;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
    }

    img {
      left: 50%;
      margin-left: -60px;
      position: relative;
      margin-top: 20px;
      margin-bottom: 10%;
    }

    &[data-open=true] {
      display: block;
    }
  }
}