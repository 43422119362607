@import '../../variables.scss'; 

.menu {
  .title {
    margin-bottom: 20px;
  }

  &-main{
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    
    .menu{
      &-item{
        width: 100%;
        margin-bottom: 20px;
        @media screen and (min-width: $tablet-max) {
          width: 50%;
        }  
      } 

      &-title{
        color: #b7903c;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 500;
        padding-right: 10px;
        margin: 0;

        &-holder{
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
        }
      }

      &-dots {
        flex-grow: 1;
        border-bottom: 2px dotted #b7903c;
      }

      &-price {
        text-transform: uppercase;
        word-wrap: break-word;
        font-size: 14px;
        line-height: 1.429em;
        color: #a72925;
        padding-left: 10px;

        @media screen and (min-width: $tablet-max) {
          padding-right: 20px;
        }  
      }

      &-description {
        color: #858585;
        margin: 0;
      }
      
    }
  }
}