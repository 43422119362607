@import '../../variables.scss'; 
 
.mySwiper {

  .swiper-slide {
    height: 100vh;
    height: 100dvh;
    max-height: 100dvh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative; 
    @for $i from 0 through 5 {
      &[data-number="#{$i}"] {
        background-image: url('../../img/pizza#{$i}.jpeg');
       
      }
    }
    @media screen and (min-width: $tablet-max) {
      background-position: right;
    }
    .text{
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      
      @media screen and (min-width: $tablet-max) {
        width: 50%;
        background-color: white;
      }
      
      h3{
        display: inline;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 80px;
        color: #b8903d;
        text-align: center;
        font-size: 25px;
        line-height: 50px;
        position: absolute;
        padding: 20px 0 30px;
        bottom: 0px;
        background-color: white;
        margin: 0;
        width: 100%;

        @media screen and (min-width: $tablet-max) {
          line-height: 70px;
          font-size: 70px;
          position: relative;
          background-color: transparent;
          padding: 0 100px;

        }
      }
    }
  }

  .swiper-button{
    &-prev, &-next{
      cursor: pointer;
      background-color: #f7f7f294;
      display: block;
      box-sizing: border-box;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      padding: 9px 19px 44px;
      transition: background-color .2s ease-in-out;
      position: absolute;
      display: block;
      z-index: 1000;

      @media screen and (min-width: $tablet-max) {
          background-color: #f7f7f2;
        }
    }
    &-prev{
      opacity: 1;
      top: 50%;
      left: -38px;
      transform: rotate(90deg);
      &:before {
        content: 'prev';
        text-transform: uppercase;
        color: #9c0504;
        letter-spacing: 2px;
        font-weight: 500;
        font-size: 12px;
      }
    }

    &-next{
      opacity: 1;
      top: 50%;
      right: -38px;
      transform: rotate(-90deg);
      &:before {
        content: 'next';
        text-transform: uppercase;
        color: #9c0504;
        letter-spacing: 2px;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }

  .scroll {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #f7f7f2;
    position: relative;
    overflow: hidden;
    text-align: center;
    text-transform: uppercase;
    color: #9c0504;
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 12px;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    z-index: 9;
    cursor: pointer;
    display: none;
    @media screen and (min-width: $tablet-max) {
      display: block;
    }
  }
  
  .scroll::after {
    content: ""; 
    width: 100%; 
    height: 50%; 
    background-color: white; 
    position: absolute; 
    bottom: 0; 
    left: 0; 
    z-index: 1; 
  }

}
