@import '../../variables.scss'; 

.gallery{
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-item{
    width: calc(33% - 20px);
    display: flex;
    margin: 10px;
    position: relative;
    @media screen and (min-width: $tablet-max) {
      width: calc(20% - 20px);
    }  
    img {
      width: 100%;
      aspect-ratio: 1; 
      object-fit: cover; 
      transition: opacity 0.2s, scale 0.4s;
    }

    
    &:after {
      content: '';
      background-image: url('../../img/instagram-gold.png');
      background-color: white;
      border-radius: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 25px;
      display: block;
      height: 25px;
      position: absolute;
      top: 50%;
      margin-top: -12.5px;
      left: 50%;
      margin-left: -12.5px;
      opacity: 0;
      scale: 0.8;
      transition: opacity 0.2s, scale 0.4s;
    }

    &:hover {
      img {
        opacity: 0.2;
        scale: 0.9;
      }
      &:after{
        opacity: 1;
        scale: 1.6;
      }
    }

   
  }
}