@import '../../variables.scss'; 

.special{
  display: flex;
  flex-wrap: wrap;
  &-item {
    display: flex;
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    padding: 20px 0;
    align-items: center;
    border-bottom: 2px solid #b8903e5e;

    @media screen and (min-width: $mobile-max) {
      width: 50%;
      max-width: 50%;
    }

    @media screen and (min-width: $tablet-max-max) {
      width: 25%;
      max-width: 25%;
    }

    &-icon {
      width: 35px;
      height: 35px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 20px;
    }
    &-name {
      margin-right: 20px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
    }

    &-price {
      margin-left: auto;
      margin-right: 50px;
      color: #a72925;
    }
  }

  &-plate {
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
  }

  &-pastry {
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: #b8903dc4;
    color: white;
    padding: 20px;

    @media screen and (min-width: $mobile-max) {
      font-size: 30px;
    line-height: 30px;
    }
    
  }
}